import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
// import { span } from "react-i18next";

class Sidebar extends Component {
	state = {};

	toggleMenuState(menuState) {
		if (this.state[menuState]) {
			this.setState({ [menuState]: false });
		} else if (Object.keys(this.state).length === 0) {
			this.setState({ [menuState]: true });
		} else {
			Object.keys(this.state).forEach((i) => {
				this.setState({ [i]: false });
			});
			this.setState({ [menuState]: true });
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		document.querySelector("#sidebar").classList.remove("active");
		Object.keys(this.state).forEach((i) => {
			this.setState({ [i]: false });
		});

		const dropdownPaths = [
			{ path: "/general-pages", state: "GeneralPagesMenuOpen" },
			{ path: "/blog-pages", state: "blogPagesMenuOpen" },
			{ path: "/career-pages", state: "careerPagesMenuOpen" },
			{ path: "/contact-pages", state: "contactPagesMenuOpen" },
			{ path: "/estimate-pages", state: "EstimatePagesMenuOpen" },
			{ path: "/faq-pages", state: "FaqPagesMenuOpen" },
			{ path: "/portfolio-pages", state: "PortfolioPagesMenuOpen" },
			{ path: "/service-pages", state: "ServicePagesMenuOpen" },
			{ path: "/testimonial-pages", state: "TestimonialPagesMenuOpen" },
		];

		dropdownPaths.forEach((obj) => {
			if (this.isPathActive(obj.path)) {
				this.setState({ [obj.state]: true });
			}
		});
	}
	render() {
		return (
			<nav className='sidebar sidebar-offcanvas' id='sidebar'>
				<div className='text-center sidebar-brand-wrapper d-flex align-items-center'>
					<a className='sidebar-brand brand-logo' href='index.html'>
						<img src={require("../../assets/images/yblogo.png")} alt='logo' />
					</a>
					<a className='sidebar-brand brand-logo-mini pt-3' href='index.html'>
						<img src={require("../../assets/images/favicon.png")} alt='logo' />
					</a>
				</div>
				<ul className='nav'>
					{/* Dashboard */}
					<li
						className={
							this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
						}>
						<Link className='nav-link' to='/dashboard'>
							<i className='mdi mdi-television menu-icon'></i>
							<span className='menu-title'>
								<span>Dashboard</span>
							</span>
						</Link>
					</li>
					{/* General Pages */}
					<li
						className={
							this.isPathActive("/general-pages") ||
							this.isPathActive("/about-page") ||
							this.isPathActive("/home-page") ||
							this.isPathActive("/footer-page")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.GeneralPagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("GeneralPagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>General</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.GeneralPagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/about-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/about-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>About Page</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/home-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/home-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Home Page</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/footer-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/footer-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Footer Page</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					{/* Blog Pages */}
					<li
						className={
							this.isPathActive("/blog-pages") ||
							this.isPathActive("/blog-category") ||
							this.isPathActive("/blog-comment") ||
							this.isPathActive("/blog-post") ||
							this.isPathActive("/blog-page")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.blogPagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("blogPagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>Blog</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.blogPagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/blog-category")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/blog-category'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Blog Category</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/blog-comment")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/blog-comment'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Blog Comment</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/blog-post")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/blog-post'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Blog Post</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/blog-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/blog-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Blog list slider</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					{/* Career Pages */}
					<li
						className={
							this.isPathActive("/career-pages") ||
							this.isPathActive("/career-form") ||
							this.isPathActive("/career-page") ||
							this.isPathActive("/career-position")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.careerPagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("careerPagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>Career</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.careerPagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/career-form")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/career-form'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Career Form</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/career-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/career-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Career Page</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/career-position")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/career-position'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Career Position</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					{/* Contact Pages */}
					<li
						className={
							this.isPathActive("/contact-pages") ||
							this.isPathActive("/contact-form") ||
							this.isPathActive("/contact-page")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.contactPagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("contactPagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>Contact</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.contactPagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/contact-form")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/contact-form'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Contact Form</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/contact-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/contact-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Contact Page</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					{/* Counter */}
					<li
						className={
							this.isPathActive("/counter") ? "nav-item active" : "nav-item"
						}>
						<Link className='nav-link' to='/counter'>
							<i className='mdi mdi-television menu-icon'></i>
							<span className='menu-title'>
								<span>Counter</span>
							</span>
						</Link>
					</li>
					{/* Country */}
					<li
						className={
							this.isPathActive("/country-page")
								? "nav-item active"
								: "nav-item"
						}>
						<Link className='nav-link' to='/country-page'>
							<i className='mdi mdi-television menu-icon'></i>
							<span className='menu-title'>
								<span>Country Page</span>
							</span>
						</Link>
					</li>
					{/* Estimate Pages */}
					<li
						className={
							this.isPathActive("/estimate-pages") ||
							this.isPathActive("/estimate-project") ||
							this.isPathActive("/estimate-detail")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.EstimatePagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("EstimatePagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>Estimate</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.EstimatePagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/estimate-project")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/estimate-project'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Estimate Project</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/estimate-detail")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/estimate-detail'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Estimate Detail</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					{/* FAQ Pages */}
					<li
						className={
							this.isPathActive("/faq-pages") ||
							this.isPathActive("/faq-category") ||
							this.isPathActive("/faq-page")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.faqPagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("faqPagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>FAQ</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.faqPagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/faq-category")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/faq-category'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>FAQ Category</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/faq-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/faq-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>FAQ Page</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					{/* Gallery */}
					<li
						className={
							this.isPathActive("/gallery-page")
								? "nav-item active"
								: "nav-item"
						}>
						<Link className='nav-link' to='/gallery-page'>
							<i className='mdi mdi-television menu-icon'></i>
							<span className='menu-title'>
								<span>Gallery Page</span>
							</span>
						</Link>
					</li>
					{/* Portfolio Pages */}
					<li
						className={
							this.isPathActive("/portfolio-pages") ||
							this.isPathActive("/portfolio-category") ||
							this.isPathActive("/portfolio-list-slider") ||
							this.isPathActive("/portfolio-page")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.PortfolioPagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("PortfolioPagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>Portfolio</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.PortfolioPagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/portfolio-category")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/portfolio-category'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Portfolio Category</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/portfolio-list-slider")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/portfolio-list-slider'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Portfolio List Slider</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/portfolio-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/portfolio-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Portfolio Page</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					{/* Privacy Policy */}
					<li
						className={
							this.isPathActive("/privacy-policy")
								? "nav-item active"
								: "nav-item"
						}>
						<Link className='nav-link' to='/privacy-policy'>
							<i className='mdi mdi-television menu-icon'></i>
							<span className='menu-title'>
								<span>Privacy Policy</span>
							</span>
						</Link>
					</li>
					{/* Service Pages */}
					<li
						className={
							this.isPathActive("/service-pages") ||
							this.isPathActive("/service-detail") ||
							this.isPathActive("/service-page")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.ServicePagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("ServicePagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>Service</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.ServicePagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/service-detail")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/service-detail'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Service Detail</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/service-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/service-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Service Page</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>
					{/* Team */}
					<li
						className={
							this.isPathActive("/team-page") ? "nav-item active" : "nav-item"
						}>
						<Link className='nav-link' to='/team-page'>
							<i className='mdi mdi-television menu-icon'></i>
							<span className='menu-title'>
								<span>Team Page</span>
							</span>
						</Link>
					</li>
					{/* Terms  */}
					<li
						className={
							this.isPathActive("/terms-use") ? "nav-item active" : "nav-item"
						}>
						<Link className='nav-link' to='/terms-use'>
							<i className='mdi mdi-television menu-icon'></i>
							<span className='menu-title'>
								<span>Terms Use</span>
							</span>
						</Link>
					</li>
					{/* Testimonial Pages */}
					<li
						className={
							this.isPathActive("/testimonial-pages") ||
							this.isPathActive("/testimonial-page") ||
							this.isPathActive("/teamtestimonial-page")
								? "nav-item active"
								: "nav-item"
						}>
						<div
							className={
								this.state.TestimonialPagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("TestimonialPagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>Testimonial</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.TestimonialPagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li
									className={
										this.isPathActive("/testimonial-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/testimonial-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Client Testimonial Page</span>
										</span>
									</Link>
								</li>
								<li
									className={
										this.isPathActive("/teamtestimonial-page")
											? "nav-item active"
											: "nav-item"
									}>
									<Link className='nav-link' to='/teamtestimonial-page'>
										<i className='mdi mdi-television menu-icon'></i>
										<span className='menu-title'>
											<span>Team Testimonial Page</span>
										</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li>

					{/* <li
						className={
							this.isPathActive("/working-process")
								? "nav-item active"
								: "nav-item"
						}>
						<Link className='nav-link' to='/working-process'>
							<i className='mdi mdi-television menu-icon'></i>
							<span className='menu-title'>
								<span>Working Process</span>
							</span>
						</Link>
					</li>
					<li
						className={
							this.isPathActive("/user-pages") ? "nav-item active" : "nav-item"
						}>
						<div
							className={
								this.state.userPagesMenuOpen
									? "nav-link menu-expanded"
									: "nav-link"
							}
							onClick={() => this.toggleMenuState("userPagesMenuOpen")}
							data-toggle='collapse'>
							<i className='mdi mdi-lock-outline menu-icon'></i>
							<span className='menu-title'>
								<span>User Pages</span>
							</span>
							<i className='menu-arrow'></i>
						</div>
						<Collapse in={this.state.userPagesMenuOpen}>
							<ul className='nav flex-column sub-menu'>
								<li className='nav-item'>
									{" "}
									<Link
										className={
											this.isPathActive("/user-pages/login-1")
												? "nav-link active"
												: "nav-link"
										}
										to='/user-pages/login-1'>
										<span>Login</span>
									</Link>
								</li>
							</ul>
						</Collapse>
					</li> */}
				</ul>
			</nav>
		);
	}

	isPathActive(path) {
		return this.props.location.pathname.startsWith(path);
	}

	componentDidMount() {
		this.onRouteChanged();
		// add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
		const body = document.querySelector("body");
		document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
			el.addEventListener("mouseover", function () {
				if (body.classList.contains("sidebar-icon-only")) {
					el.classList.add("hover-open");
				}
			});
			el.addEventListener("mouseout", function () {
				if (body.classList.contains("sidebar-icon-only")) {
					el.classList.remove("hover-open");
				}
			});
		});
	}
}

export default withRouter(Sidebar);
