import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

function handelRightClick(event) {
	event.preventDefault();
}

ReactDOM.render(
	<Router basename={"/"}>
		<QueryClientProvider client={queryClient}>
			{document.addEventListener("contextmenu", handelRightClick)}
			<App />
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</Router>,
	document.getElementById("root"),
);

serviceWorker.unregister();
