import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Error404 from "./shared/Error404";

const PrivateRoute = ({ component: Component, restricted, ...rest }) => {
	let admin_id = Cookies.get("admin_id");
	return (
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /signin page
		<>
			<Route
				{...rest}
				render={(props) =>
					// !restricted || restricted === undefined ? (
					// 	<Redirect to='/404' />
					// ) :
					admin_id ? <Component {...props} /> : <Redirect to='/' />
				}
			/>
		</>
	);
};

export default PrivateRoute;
