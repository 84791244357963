import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

const Login = lazy(() => import("./user-pages/Login"));
const ForgotPassword = lazy(() => import("./user-pages/forgot-password"));
const ResetPassword = lazy(() => import("./user-pages/reset-password"));
// const Register1 = lazy(() => import('./user-pages/Register'));
const Error404 = lazy(() => import("./shared/Error404"));

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const ChangePassword = lazy(() => import("./user-pages/change-password"));
const ManageAccount = lazy(() => import("./user-pages/manage-account"));

const HomePageList = lazy(() => import("./HomePage/HomePageList"));
const HomePageCreate = lazy(() => import("./HomePage/HomePageCreate"));

const TeamPageList = lazy(() => import("./TeamPage/TeamPageList"));
const TeamPageCreate = lazy(() => import("./TeamPage/TeamPageCreate"));

const TestimonialPageList = lazy(() =>
	import("./TestimonialPage/TestimonialPageList"),
);
const TestimonialPageCreate = lazy(() =>
	import("./TestimonialPage/TestimonialPageCreate"),
);

const TeamTestimonialPageList = lazy(() =>
	import("./TeamTestimonialPage/TeamTestimonialPageList"),
);
const TeamTestimonialPageCreate = lazy(() =>
	import("./TeamTestimonialPage/TeamTestimonialPageCreate"),
);

const BlogList = lazy(() => import("./Blog/BlogList"));
const BlogCreate = lazy(() => import("./Blog/BlogCreate"));

const BlogPageList = lazy(() => import("./BlogPage/BlogPageList"));
const BlogPageCreate = lazy(() => import("./BlogPage/BlogPageCreate"));

const BlogCategoryList = lazy(() => import("./BlogCategory/BlogCategoryList"));
const BlogCategoryCreate = lazy(() =>
	import("./BlogCategory/BlogCategoryCreate"),
);

const ServicePageList = lazy(() => import("./ServicePage/ServicePageList"));
const ServicePageCreate = lazy(() => import("./ServicePage/ServicePageCreate"));

const FooterPageList = lazy(() => import("./FooterPage/FooterPageList"));
const FooterPageCreate = lazy(() => import("./FooterPage/FooterPageCreate"));

const AboutPageList = lazy(() => import("./AboutPage/AboutPageList"));
const AboutPageCreate = lazy(() => import("./AboutPage/AboutPageCreate"));

const CounterList = lazy(() => import("./Counter/CounterList"));
const CounterCreate = lazy(() => import("./Counter/CounterCreate"));

const CountryPageList = lazy(() => import("./CountryPage/CountryPageList"));
const CountryPageCreate = lazy(() => import("./CountryPage/CountryPageCreate"));

const ContactPageList = lazy(() => import("./ContactPage/ContactPageList"));
const ContactPageCreate = lazy(() => import("./ContactPage/ContactPageCreate"));

const ContactFormList = lazy(() => import("./ContactForm/ContactFormList"));
const ContactFormCreate = lazy(() => import("./ContactForm/ContactFormCreate"));

const ServiceDetailPageList = lazy(() =>
	import("./SeriveDetailPage/ServiceDetailPageList"),
);
const ServiceDetailPageCreate = lazy(() =>
	import("./SeriveDetailPage/ServiceDetailPageCreate"),
);

const CareerPageList = lazy(() => import("./CareerPage/CareerPageList"));
const CareerPageCreate = lazy(() => import("./CareerPage/CareerPageCreate"));

const CareerPositionList = lazy(() =>
	import("./CareerPosition/CareerPositionList"),
);
const CareerPositionCreate = lazy(() =>
	import("./CareerPosition/CareerPositionCreate"),
);

const CareerFormList = lazy(() => import("./CareerForm/CareerFormList"));
const CareerFormCreate = lazy(() => import("./CareerForm/CareerFormCreate"));

const BlogCommentList = lazy(() => import("./BlogComment/BlogCommentList"));
const BlogCommentCreate = lazy(() => import("./BlogComment/BlogCommentCreate"));

const PortfolioPageList = lazy(() =>
	import("./PortfolioPage/PortfolioPageList"),
);
const PortfolioPageCreate = lazy(() =>
	import("./PortfolioPage/PortfolioPageCreate"),
);

const PortfolioListSlider = lazy(() =>
	import("./PortfolioListSlider/PortfolioListSlider"),
);
const PortfolioListSliderCreate = lazy(() =>
	import("./PortfolioListSlider/PortfolioListSliderCreate"),
);

const PortfolioCategoryList = lazy(() =>
	import("./PortfolioCategory/PortfolioCategoryList"),
);
const PortfolioCategoryCreate = lazy(() =>
	import("./PortfolioCategory/PortfolioCategoryCreate"),
);

const GalleryPageList = lazy(() => import("./GalleryPage/GalleryPageList"));
const GalleryPageCreate = lazy(() => import("./GalleryPage/GalleryPageCreate"));

const PrivacyPolicyList = lazy(() =>
	import("./PrivacyPolicy/PrivacyPolicyList"),
);
const PrivacyPolicyCreate = lazy(() =>
	import("./PrivacyPolicy/PrivacyPolicyCreate"),
);

const TermsUseList = lazy(() => import("./TermsUse/TermsUseList"));
const TermsUseCreate = lazy(() => import("./TermsUse/TermsUseCreate"));

const EstimateProjectList = lazy(() =>
	import("./EstimateProject/EstimateProjectList"),
);
const EstimateProjectCreate = lazy(() =>
	import("./EstimateProject/EstimateProjectCreate"),
);

const FaqCategoryList = lazy(() => import("./FaqCategory/FaqCategoryList"));
const FaqCategoryCreate = lazy(() => import("./FaqCategory/FaqCategoryCreate"));

const FaqPageList = lazy(() => import("./FaqPage/FaqPageList"));
const FaqPageCreate = lazy(() => import("./FaqPage/FaqPageCreate"));

const WorkingProcessList = lazy(() =>
	import("./WorkingProcess/WorkingProcessList"),
);
const WorkingProcessCreate = lazy(() =>
	import("./WorkingProcess/WorkingProcessCreate"),
);

const EstimateDetailList = lazy(() =>
	import("./EstimateDetail/EstimateDetailList"),
);
const EstimateDetailCreate = lazy(() =>
	import("./EstimateDetail/EstimateDetailCreate"),
);

const ServiceCategoryList = lazy(() =>
	import("./ServiceCategory/ServiceCategoryList"),
);
const ServiceCategoryCreate = lazy(() =>
	import("./ServiceCategory/ServiceCategoryCreate"),
);

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const AppRoutes = () => {
	return (
		<Suspense fallback={<Spinner />}>
			<Switch>
				<>
					<PublicRoute restricted={true} path='/' component={Login} exact />

					<PublicRoute
						exact
						path='/user-pages/forgot-password'
						component={ForgotPassword}
					/>
					<PublicRoute
						exact
						path='/user-pages/reset-password/:email/:token'
						component={ResetPassword}
					/>
					{/* <PublicRoute exact path="/user-pages/register-1" component={ Register1 } /> */}

					<PrivateRoute
						exact
						path='/dashboard'
						restricted={true}
						component={Dashboard}
					/>
					<PrivateRoute
						exact
						path='/change-password'
						component={ChangePassword}
					/>
					<PrivateRoute
						exact
						path='/manage-account'
						component={ManageAccount}
						restricted={true}
					/>
					<PrivateRoute
						exact
						path='/home-page'
						restricted={true}
						component={HomePageList}
					/>
					<PrivateRoute
						exact
						path='/home-page/add'
						component={HomePageCreate}
					/>
					<PrivateRoute
						exact
						path='/home-page/edit/:home_id'
						component={HomePageCreate}
					/>

					<PrivateRoute exact path='/team-page' component={TeamPageList} />
					<PrivateRoute
						exact
						path='/team-page/add'
						component={TeamPageCreate}
					/>
					<PrivateRoute
						exact
						path='/team-page/edit/:team_id'
						component={TeamPageCreate}
					/>

					<PrivateRoute
						exact
						path='/testimonial-page'
						component={TestimonialPageList}
					/>
					<PrivateRoute
						exact
						path='/testimonial-page/add'
						component={TestimonialPageCreate}
					/>
					<PrivateRoute
						exact
						path='/testimonial-page/edit/:testimonial_id'
						component={TestimonialPageCreate}
					/>

					<PrivateRoute
						exact
						path='/teamtestimonial-page'
						component={TeamTestimonialPageList}
					/>
					<PrivateRoute
						exact
						path='/teamtestimonial-page/add'
						component={TeamTestimonialPageCreate}
					/>
					<PrivateRoute
						exact
						path='/teamtestimonial-page/edit/:testimonial_id'
						component={TeamTestimonialPageCreate}
					/>

					<PrivateRoute exact path='/blog-post' component={BlogList} />
					<PrivateRoute exact path='/blog-post/add' component={BlogCreate} />
					<PrivateRoute
						exact
						path='/blog-post/edit/:blog_id'
						component={BlogCreate}
					/>

					<PrivateRoute exact path='/blog-page' component={BlogPageList} />
					<PrivateRoute
						exact
						path='/blog-page/add'
						component={BlogPageCreate}
					/>
					<PrivateRoute
						exact
						path='/blog-page/edit/:blog_id'
						component={BlogPageCreate}
					/>

					<PrivateRoute
						exact
						path='/blog-category'
						component={BlogCategoryList}
					/>
					<PrivateRoute
						exact
						path='/blog-category/add'
						component={BlogCategoryCreate}
					/>
					<PrivateRoute
						exact
						path='/blog-category/edit/:category_id'
						component={BlogCategoryCreate}
					/>

					<PrivateRoute
						exact
						path='/service-page'
						component={ServicePageList}
					/>
					<PrivateRoute
						exact
						path='/service-page/add'
						component={ServicePageCreate}
					/>
					<PrivateRoute
						exact
						path='/service-page/edit/:service_id'
						component={ServicePageCreate}
					/>

					<PrivateRoute exact path='/footer-page' component={FooterPageList} />
					<PrivateRoute
						exact
						path='/footer-page/add'
						component={FooterPageCreate}
					/>
					<PrivateRoute
						exact
						path='/footer-page/edit/:footer_id'
						component={FooterPageCreate}
					/>

					<PrivateRoute exact path='/about-page' component={AboutPageList} />
					<PrivateRoute
						exact
						path='/about-page/add'
						component={AboutPageCreate}
					/>
					<PrivateRoute
						exact
						path='/about-page/edit/:about_id'
						component={AboutPageCreate}
					/>

					<PrivateRoute exact path='/counter' component={CounterList} />
					<PrivateRoute exact path='/counter/add' component={CounterCreate} />
					<PrivateRoute
						exact
						path='/counter/edit/:counter_id'
						component={CounterCreate}
					/>

					<PrivateRoute
						exact
						path='/country-page'
						component={CountryPageList}
					/>
					<PrivateRoute
						exact
						path='/country-page/add'
						component={CountryPageCreate}
					/>
					<PrivateRoute
						exact
						path='/country-page/edit/:country_id'
						component={CountryPageCreate}
					/>

					<PrivateRoute
						exact
						path='/contact-page'
						component={ContactPageList}
					/>
					<PrivateRoute
						exact
						path='/contact-page/add'
						component={ContactPageCreate}
					/>
					<PrivateRoute
						exact
						path='/contact-page/edit/:contact_id'
						component={ContactPageCreate}
					/>

					<PrivateRoute
						exact
						path='/contact-form'
						component={ContactFormList}
					/>
					<PrivateRoute
						exact
						path='/contact-form/add'
						component={ContactFormCreate}
					/>
					<PrivateRoute
						exact
						path='/contact-form/edit/:cf_id'
						component={ContactFormCreate}
					/>

					<PrivateRoute
						exact
						path='/service-detail'
						component={ServiceDetailPageList}
					/>
					<PrivateRoute
						exact
						path='/service-detail/add'
						component={ServiceDetailPageCreate}
					/>
					<PrivateRoute
						exact
						path='/service-detail/edit/:service_detail_id'
						component={ServiceDetailPageCreate}
					/>

					<PrivateRoute exact path='/career-page' component={CareerPageList} />
					<PrivateRoute
						exact
						path='/career-page/add'
						component={CareerPageCreate}
					/>
					<PrivateRoute
						exact
						path='/career-page/edit/:career_id'
						component={CareerPageCreate}
					/>

					<PrivateRoute
						exact
						path='/career-position'
						component={CareerPositionList}
					/>
					<PrivateRoute
						exact
						path='/career-position/add'
						component={CareerPositionCreate}
					/>
					<PrivateRoute
						exact
						path='/career-position/edit/:cp_id'
						component={CareerPositionCreate}
					/>

					<PrivateRoute exact path='/career-form' component={CareerFormList} />
					<PrivateRoute
						exact
						path='/career-form/add'
						component={CareerFormCreate}
					/>
					<PrivateRoute
						exact
						path='/career-form/edit/:cf_id'
						component={CareerFormCreate}
					/>

					<PrivateRoute
						exact
						path='/blog-comment'
						component={BlogCommentList}
					/>
					<PrivateRoute
						exact
						path='/blog-comment/add'
						component={BlogCommentCreate}
					/>
					<PrivateRoute
						exact
						path='/blog-comment/edit/:comment_id'
						component={BlogCommentCreate}
					/>
					<PrivateRoute
						exact
						path='/portfolio-list-slider'
						component={PortfolioListSlider}
					/>
					<PrivateRoute
						exact
						path='/portfolio-list-slider/add'
						component={PortfolioListSliderCreate}
					/>
					<PrivateRoute
						exact
						path='/portfolio-list-slider/edit/:portfolio_id'
						component={PortfolioListSliderCreate}
					/>
					<PrivateRoute
						exact
						path='/portfolio-page'
						component={PortfolioPageList}
					/>
					<PrivateRoute
						exact
						path='/portfolio-page/add'
						component={PortfolioPageCreate}
					/>
					<PrivateRoute
						exact
						path='/portfolio-page/edit/:portfolio_id'
						component={PortfolioPageCreate}
					/>

					<PrivateRoute
						exact
						path='/portfolio-category'
						component={PortfolioCategoryList}
					/>
					<PrivateRoute
						exact
						path='/portfolio-category/add'
						component={PortfolioCategoryCreate}
					/>
					<PrivateRoute
						exact
						path='/portfolio-category/edit/:pc_id'
						component={PortfolioCategoryCreate}
					/>

					<PrivateRoute
						exact
						path='/gallery-page'
						component={GalleryPageList}
					/>
					<PrivateRoute
						exact
						path='/gallery-page/add'
						component={GalleryPageCreate}
					/>
					<PrivateRoute
						exact
						path='/gallery-page/edit/:gallery_id'
						component={GalleryPageCreate}
					/>

					<PrivateRoute
						exact
						path='/privacy-policy'
						component={PrivacyPolicyList}
					/>
					<PrivateRoute
						exact
						path='/privacy-policy/add'
						component={PrivacyPolicyCreate}
					/>
					<PrivateRoute
						exact
						path='/privacy-policy/edit/:pp_id'
						component={PrivacyPolicyCreate}
					/>

					<PrivateRoute exact path='/terms-use' component={TermsUseList} />
					<PrivateRoute
						exact
						path='/terms-use/add'
						component={TermsUseCreate}
					/>
					<PrivateRoute
						exact
						path='/terms-use/edit/:term_id'
						component={TermsUseCreate}
					/>

					<PrivateRoute
						exact
						path='/estimate-project'
						component={EstimateProjectList}
					/>
					<PrivateRoute
						exact
						path='/estimate-project/add'
						component={EstimateProjectCreate}
					/>
					<PrivateRoute
						exact
						path='/estimate-project/edit/:ep_id'
						component={EstimateProjectCreate}
					/>

					<PrivateRoute
						exact
						path='/faq-category'
						component={FaqCategoryList}
					/>
					<PrivateRoute
						exact
						path='/faq-category/add'
						component={FaqCategoryCreate}
					/>
					<PrivateRoute
						exact
						path='/faq-category/edit/:category_id'
						component={FaqCategoryCreate}
					/>

					<PrivateRoute exact path='/faq-page' component={FaqPageList} />
					<PrivateRoute exact path='/faq-page/add' component={FaqPageCreate} />
					<PrivateRoute
						exact
						path='/faq-page/edit/:faq_id'
						component={FaqPageCreate}
					/>

					<PrivateRoute
						exact
						path='/working-process'
						component={WorkingProcessList}
					/>
					<PrivateRoute
						exact
						path='/working-process/add'
						component={WorkingProcessCreate}
					/>
					<PrivateRoute
						exact
						path='/working-process/edit/:wp_id'
						component={WorkingProcessCreate}
					/>

					<PrivateRoute
						exact
						path='/estimate-detail'
						component={EstimateDetailList}
					/>
					<PrivateRoute
						exact
						path='/estimate-detail/add'
						component={EstimateDetailCreate}
					/>
					<PrivateRoute
						exact
						path='/estimate-detail/edit/:ed_id'
						component={EstimateDetailCreate}
					/>

					<PrivateRoute
						exact
						path='/service-category'
						component={ServiceCategoryList}
					/>
					<PrivateRoute
						exact
						path='/service-category/add'
						component={ServiceCategoryCreate}
					/>
					<PrivateRoute
						exact
						path='/service-category/edit/:category_id'
						component={ServiceCategoryCreate}
					/>

					<PrivateRoute path='/basic-ui/buttons' component={Buttons} />
					<PrivateRoute path='/basic-ui/dropdowns' component={Dropdowns} />

					{/* <PrivateRoute path="/form-Elements/basic-elements" component={ BasicElements } />

          <PrivateRoute path="/tables/basic-table" component={ BasicTable } />*/}

					<PrivateRoute path='/icons/mdi' component={Mdi} />

					<PrivateRoute path='/charts/chart-js' component={ChartJs} />

					{/* <PrivateRoute path='/Error404' component={Error404} /> */}

					{/* <Error404 /> */}
					<Route path='/404' exact component={Error404} />
				</>
			</Switch>
		</Suspense>
	);
};

export default AppRoutes;
