import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
	let admin_id = Cookies.get("admin_id");
	// console.log("Data", admin_id, restricted);
	return (
		// restricted = false meaning public route
		// restricted = true meaning restricted route
		<Route
			{...rest}
			render={(props) =>
				admin_id && restricted ? (
					<Redirect to='/dashboard' />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default PublicRoute;
